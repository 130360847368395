import React, { Component } from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import Layout from '../components/layouts'

export default class WomensIdeation extends Component {
  render() {
    const sketches = this.props.data.allContentfulIdeations.edges[0].node.images.map(
      (img) => (
        <GatsbyImage
          key={img.id}
          image={img.gatsbyImageData}
          alt={img.title}
          className="fadeInUp sketch_img"
        />
      ),
    )
    return (
      <Layout>
        <div className="sketches">{sketches}</div>
      </Layout>
    )
  }
}

export const query = graphql`
  query WomensIdeation {
    allContentfulIdeations(filter: { title: { eq: "Womens Ideation" } }) {
      edges {
        node {
          images {
            id
            title
            gatsbyImageData(placeholder: NONE)
          }
        }
      }
    }
  }
`
